<template>
  <a-dropdown placement="bottomRight" :trigger="['click']">
    <a-badge
      :count="badgeCount"
      :overflowCount="99"
      :numberStyle="{ padding: '0 6px', height: '14px', lineHeight: '14px' }"
    >
      <a-icon type="bell" :style="{ fontSize: '20px', color: '#fff' }" />
    </a-badge>
    <template #overlay> </template>
  </a-dropdown>
</template>
<script>
export default {
  data() {
    return {
      badgeCount: 0,
    };
  },
};
</script>

<style lang="less" scoped></style>
