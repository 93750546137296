import { login, logout } from "@/apis/user";
import {
  ACCESS_TOKEN,
  USER_INFO,
  // PROJECTS,
  // CURRENT_PROJECT,
} from "@/store/mutation-types";
import { local } from "@/utils/util";

const user = {
  state: {
    userInfo: local.get(USER_INFO) || {}, // 用户信息
    // projects: local.get(PROJECTS) || [], // 用户所属项目
    // currentProject: local.get(CURRENT_PROJECT) || null, //用户当前查看的项目
  },
  mutations: {
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    // SET_PROJECTS: (state, projects) => {
    //   state.projects = projects;
    // },
    // SET_CURRENTPROJECT: (state, currentProject) => {
    //   local.set(CURRENT_PROJECT, currentProject);
    //   state.currentProject = currentProject;
    // },
  },
  actions: {
    Login({ commit }, loginForm) {
      return new Promise((resolve, reject) => {
        login(loginForm)
          .then((res) => {
            const { token, name } = res.data.data.resList;
            local.set(ACCESS_TOKEN, token);
            local.set(USER_INFO, { name });
            commit("SET_USERINFO", { name });
            resolve();
          })
          .then((error) => {
            reject(error);
          });
      });
    },
    // GetProjectList({ commit }, userId) {
    //   return new Promise((resolve, reject) => {
    //     getProjectList({ guyId: userId })
    //       .then((response) => {
    //         const { res } = response.data.data;
    //         local.set(PROJECTS, res);
    //         local.set(CURRENT_PROJECT, res[0].projectId);
    //         commit("SET_PROJECTS", res);
    //         commit("SET_CURRENTPROJECT", res[0].projectId);
    //         resolve();
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
    Logout({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            local.set(ACCESS_TOKEN, null);
            // local.set(PROJECTS, []);
            // local.set(CURRENT_PROJECT, null);
            // commit("SET_PROJECTS", []);
            // commit("SET_CURRENTPROJECT", null);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default user;
