<template functional>
  <a-sub-menu :key="props.menuInfo.path" v-bind="$props" v-on="$listeners">
    <span slot="title">
      <a-icon
        v-if="props.menuInfo.meta.icon"
        :type="props.menuInfo.meta.icon"
      />
      <span>{{ props.menuInfo.meta.title }}</span>
    </span>
    <template v-for="item in props.menuInfo.children">
      <a-menu-item v-if="!item.children && !item.hidden" :key="item.path">
        <span>
          <a-icon v-if="item.meta.icon" :type="item.meta.icon" />
          {{ item.meta.title }}
        </span>
      </a-menu-item>
      <sub-menu
        v-else-if="item.children && !item.hidden"
        :menuInfo="item"
        :key="item.path"
      />
    </template>
  </a-sub-menu>
</template>

<script>
export default {
  props: ["menuInfo"],
};
</script>

<style lang="less" scoped></style>
