import axios from "axios";
import router from "@/router";
import store from "@/store";
import notification from "ant-design-vue/es/notification";
import defaultSettings from "../config/defaultSettings";
// import { local } from "./util";
// import { ACCESS_TOKEN } from "@/store/mutation-types";

// 创建实例，基础配置
const request = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" ? process.env.VUE_APP_URL : "/",
  timeout: 10000,
});

// 设置带上cookie
request.defaults.withCredentials = true;

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // if (local.get(ACCESS_TOKEN)) {
    //   config.headers["Authorization"] = local.get(ACCESS_TOKEN);
    // }
    // const currentProject = store.getters.currentProject;
    const userInfo = store.getters.userInfo;
    config.headers["Content-Type"] = "application/json";
    // config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    // if (currentProject) {
    config.data = {
      projectId: defaultSettings.projectId,
      userId: userInfo.name,
      ...config.data,
    };
    const url = config?.url;
    if (url && url.indexOf("diti") > 0) delete config.data.projectId;
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    const { code } = response.data;
    if (code === "200") {
      return Promise.resolve(response);
    } else {
      errorHandler(response.data);
    }
  },
  (error) => {
    const { message } = error;
    if (message) {
      const arr = message.split(" ");
      let err = {
        code: arr[arr.length - 1],
      };
      errorHandler(err);
    }
  }
);

const errorHandler = async (error) => {
  const { code } = error;
  if (code) {
    switch (parseInt(code)) {
      // 401 跳转登录页并携带当前页面路径参数，登陆后返回该页面
      case 401:
        notification.error({
          message: "未授权",
          description: "权限验证失败",
        });
        // if (local.get(ACCESS_TOKEN)) {
        store.dispatch("Logout").then(() => {
          const { query } = router.currentRoute;
          setTimeout(() => {
            router.push({
              path: "/user/login",
              query: query.redirect
                ? query
                : { redirect: router.currentRoute.fullPath, ...query },
            });
          }, 1500);
        });
        // }
        break;

      // 403 token过期
      // 登录过期对用户进行提示
      // 清除本地token和清空vuex中token对象
      // 跳转登录页并携带当前页面路径参数，登陆后返回该页面
      case 403:
        notification.error({
          message: "请求异常",
          description: "登录过期，请重新登录",
        });
        store.dispatch("Logout").then(() => {
          const { query } = router.currentRoute;
          setTimeout(() => {
            router.push({
              path: "/user/login",
              query: query.redirect
                ? query
                : { redirect: router.currentRoute.fullPath, ...query },
            });
          }, 1500);
        });
        break;

      // 404请求不存在
      case 404:
        notification.error({
          message: "请求异常",
          description: "网络请求不存在",
        });
        break;

      // 其他错误，直接抛出错误提示
      default:
        notification.error({
          message: "请求异常",
          description: error.note,
        });
        break;
    }
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
};

export default request;
