<template>
  <a-dropdown placement="bottomRight" :trigger="['hover']">
    <span>
      <a-avatar :src="require('@/assets/avatar@2x.png')" />
      <span>{{ userInfo.mobile }}</span>
    </span>
    <template #overlay>
      <a-menu @click="menuClick" style="width: 110px; text-align: center">
        <a-menu-item key="userInfo"><a-icon type="user" />用户信息</a-menu-item>
        <a-menu-divider />
        <a-menu-item key="logout">
          <a-icon type="poweroff" />退出登录
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { Modal } from "ant-design-vue";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({ userInfo: "userInfo" }),
  },
  methods: {
    menuClick({ key }) {
      if (key === "userInfo") {
        Modal.info({
          title: "用户信息",
          content: (h) =>
            h("p", {}, [
              h("p", `用户姓名：${this.userInfo.name}`),
              h("p", `用户电话：${this.userInfo.mobile}`),
            ]),
        });
      } else if (key === "logout") {
        this.$store.dispatch("Logout").then(() => {
          this.$router.push("/user/login");
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ant-avatar {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}
</style>
