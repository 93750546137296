<template>
  <a-layout class="basic-layout">
    <!-- 公共头部 -->
    <GlobalHeader />
    <a-layout class="basic-layout-wrp">
      <!-- 公共侧边栏 -->
      <SiderMenu />
      <!-- 页面正文 -->
      <a-layout-content class="basic-layout-content">
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader";
import SiderMenu from "@/components/SiderMenu";

export default {
  components: {
    GlobalHeader,
    SiderMenu,
  },
};
</script>

<style lang="less" scoped>
.basic-layout {
  height: 100%;
  .basic-layout-content {
    padding: 20px;
  }
}
</style>
