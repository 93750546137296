import request from "@/utils/request";

function login(data) {
  return request({
    url: "/sys/show/mgr/login",
    method: "post",
    data,
  });
}

function logout(data) {
  return request({
    url: "/sys/show/mgr/logout",
    method: "post",
    data,
  });
}

// function getProjectList(data) {
//   return request({
//     url: "/sys/member/projectList",
//     method: "post",
//     data,
//   });
// }

export { login, logout };
