<template>
  <a-layout-header class="global-header">
    <div class="global-header-left">
      <a href="#/">
        <img src="@/assets/logo.png" />
        <h1>就在直播</h1>
      </a>
      <!-- <img class="logo" src="../../assets/logo@2x.png" alt="" />
      <a-select
        :value="currentProject"
        style="width: 200px"
        @change="handleChange"
      >
        <a-icon
          slot="suffixIcon"
          type="caret-down"
          style="color: #fff; font-size: 16px"
        />
        <a-select-option
          v-for="item in projectList"
          :key="item.projectId"
          :value="item.projectId"
          >{{ item.displayName + "运维平台" }}</a-select-option
        >
      </a-select> -->
    </div>
    <div class="global-header-navmenu">
      <a-menu :selectedKeys="currentPath" mode="horizontal">
        <a-menu-item
          v-for="item in menuMap"
          :key="item.path"
          @click="changeRoute"
        >
          <component v-if="item.meta.icon" :is="item.meta.icon" />
          {{ item.meta.title }}
        </a-menu-item>
      </a-menu>
    </div>

    <div class="global-header-right">
      <RightContent />
    </div>
  </a-layout-header>
</template>

<script>
import RightContent from "./RightContent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    RightContent,
  },
  data() {
    return {
      menuMap: [], // 全部左侧菜单
      openKeys: [], // 展开的菜单路径
      currentPath: [], // 当前选中的菜单路径
    };
  },
  computed: {
    ...mapGetters({
      projectList: "projects",
      currentProject: "currentProject",
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    // 切换项目
    handleChange(value) {
      this.$store.commit("SET_CURRENTPROJECT", value);
    },
    // 切换顶部菜单
    changeRoute({ key }) {
      this.currentPath = [key];
      const { path } = this.$route;
      if ("/" + path.split("/")[1] !== key) this.$router.push(key);
    },
    init() {
      const { path } = this.$route;
      const { routes } = this.$router.options;
      this.currentPath = ["/" + path.split("/")[1]];
      this.menuMap = routes.find((item) => item.path === "/").children;
    },
  },
};
</script>

<style lang="less" scoped>
.global-header {
  height: 48px;
  line-height: 48px;
  padding: 0 24px;
  background: url("../../assets/header_bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  .global-header-left {
    width: 250px;
    a {
      display: flex;
      align-items: center;
      height: 100%;
      text-decoration: none;
    }
    img {
      height: 28px;
      vertical-align: middle;
      border-style: none;
    }
    h1 {
      height: 32px;
      margin: 0 0 0 12px;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      color: #fff;
    }
    // .logo {
    //   width: 32px;
    //   height: 32px;
    //   margin-right: 10px;
    // }
    // /deep/ .ant-select {
    //   margin-top: 6px;
    //   line-height: 32px;
    //   .ant-select-selection {
    //     border: none;
    //     background-color: transparent;
    //     color: #fff;
    //     font-size: 20px;
    //   }
    //   .ant-select-arrow {
    //     width: 18px;
    //     height: 16px;
    //     top: 50%;
    //     margin-top: 0;
    //     transform: translateY(-50%);
    //   }
    //   .ant-select-selection:active {
    //     box-shadow: none;
    //     border: none;
    //   }
    // }
  }
  .global-header-navmenu {
    flex: 1 1 0%;
    /deep/ .ant-menu {
      height: 46px;
      line-height: 48px;
      background-color: transparent;
      color: rgba(255, 255, 255, 0.65);
      border: none;
      font-size: 16px;
      .ant-menu-item {
        height: 100%;
        padding: 0 20px;
        margin: 0 10px;
      }
      .ant-menu-item-active {
        color: rgba(255, 255, 255, 1);
        border-bottom: 4px solid transparent;
      }
      .ant-menu-item-selected {
        color: rgba(255, 255, 255, 1);
        border-bottom: 4px solid #fff;
      }
    }
  }
  .global-header-right {
    min-width: 200px;
  }
}
</style>
