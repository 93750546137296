import Vue from "vue";
import VueRouter from "vue-router";
import routerMap from "./routerMap";
import { local } from "@/utils/util";
import {
  ACCESS_TOKEN,
  // PROJECTS,
  // CURRENT_PROJECT,
} from "@/store/mutation-types";
import defaultSettings from "@/config/defaultSettings";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: routerMap,
});

router.beforeEach((to, from, next) => {
  // 修改标题;
  if (to.meta.title) {
    // if (!Vue.prototype.$_.isEmpty(local.get(PROJECTS))) {
    //   const projects = local.get(PROJECTS);
    //   const currentProject = local.get(CURRENT_PROJECT);
    //   if (projects.find((r) => r.projectId === currentProject)) {
    //     const projectName = projects.find(
    //       (r) => r.projectId === currentProject
    //     ).displayName;
    //     document.title = to.meta.title + "-" + projectName;
    //   }
    // } else {
    //   document.title = to.meta.title;
    // }
    document.title = to.meta.title + "-" + defaultSettings.title;
  } else {
    document.title = defaultSettings.title;
  }

  // next();
  // 判断是否登录
  // console.log(to, from);
  if (
    to.name !== "Login" &&
    // Vue.prototype.$_.isEmpty(local.get(ACCESS_TOKEN))
    Vue.prototype.$_.isEmpty(local.get(ACCESS_TOKEN))
  ) {
    if (to.name == from.name) {
      return false;
    }
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
