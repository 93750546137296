<template>
  <a-layout-sider class="sider-menu">
    <a-menu
      :openKeys="openKeys"
      :selectedKeys="currentPath"
      mode="inline"
      theme="dark"
      :inlineCollapsed="collapsed"
      @click="changeRoute"
    >
      <template v-for="item in menuMap">
        <template v-if="!item.hidden">
          <a-menu-item v-if="!item.children && !item.hidden" :key="item.path">
            <a-icon v-if="item.meta.icon" :type="item.meta.icon" />
            <span>{{ item.meta.title }}</span>
          </a-menu-item>
          <SubMenu
            v-else-if="item.children && !item.hidden"
            :menuInfo="item"
            :key="item.path"
          />
        </template>
      </template>
    </a-menu>
    <!-- <a-button type="primary" class="collapsedBtn" @click="toggleCollapsed">
      <MenuUnfoldOutlined v-if="collapsed" />
      <MenuFoldOutlined v-else />
    </a-button> -->
  </a-layout-sider>
</template>

<script>
import SubMenu from "./SubMenu";
// import _ from "lodash";

export default {
  components: {
    SubMenu,
  },
  data() {
    return {
      menuMap: [], // 全部左侧菜单
      openKeys: [], // 展开的菜单路径
      currentPath: [], // 当前选中的菜单路径
      collapsed: false, // 菜单是否折叠
    };
  },
  created() {
    this.getMenuState();
  },
  watch: {
    $route() {
      this.getMenuState();
    },
  },
  methods: {
    // 获取菜单数据，修改选中菜单
    getMenuState() {
      const { path } = this.$route;
      const { routes } = this.$router.options;
      this.menuMap = routes
        .find((item) => item.path === "/")
        .children.find(
          (option) => option.path === `/${path.split("/")[1]}`
        )?.children;
      // this.openKeys = [`/${path.split("/")[1]}/${path.split("/")[2]}`];
      this.openKeys = this.menuMap.map((r) => r.path);
      this.currentPath = [`${path.split("/").splice(0, 4).join("/")}`];
    },
    // 切换菜单
    changeRoute({ key }) {
      // this.openKeys = [`/${key.split("/")[1]}/${key.split("/")[2]}`];
      // this.currentPath = [key];
      const { path } = this.$route;
      if (path !== key) this.$router.push(key);
    },
  },
};
</script>

<style lang="less" scoped>
.sider-menu {
  position: relative;
  background-color: #3a9bff;
  height: 100%;
  overflow-y: scroll;
  /deep/ .ant-menu {
    background-color: #3a9bff;
    .ant-menu-submenu > .ant-menu {
      background-color: #3a9bff;
      box-shadow: none;
    }
    .ant-menu-item-active {
      background-color: transparent;
    }
    .ant-menu-item-selected {
      color: #246dcc;
      background-color: #fff !important;
      .anticon {
        color: #246dcc;
      }
    }
    .ant-menu-item {
      width: calc(100% - 24px);
      padding-left: 24px;
      margin-left: 24px;
      border-radius: 20px 0 0 20px;
    }
    .ant-menu-item-selected::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 100%;
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
    }
    .ant-menu-item::after {
      border: none;
    }
  }
  .collapsedBtn {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
::-webkit-scrollbar {
  width: 0;
}
</style>
