<template>
  <a-layout class="user-layout">
    <a-layout-content class="user-layout-content">
      <router-view />
    </a-layout-content>
    <a-layout-footer class="user-layout-footer" v-if="!settings.hiddenFooter">
      <a-space>
        <a href="https://beian.miit.gov.cn" target="_blank">
          <a-icon type="copyright" />{{ settings.copyright }}
        </a>
      </a-space>
    </a-layout-footer>
  </a-layout>
</template>

<script>
import defaultSettings from "@/config/defaultSettings";
export default {
  data() {
    return {
      settings: defaultSettings,
    };
  },
};
</script>

<style lang="less" scoped>
.user-layout {
  width: 100%;
  height: 100%;
  background: url("../assets/login_bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .user-layout-content {
    height: 100%;
  }
  .user-layout-footer {
    height: 48px;
    line-height: 48px;
    padding: 0;
    text-align: center;
    background-color: transparent;
    a {
      color: #fff;
    }
  }
}
</style>
