import { UserLayout, BasicLayout, BlankLayout } from "@/layouts";

const routerMap = [
  {
    path: "/user",
    name: "User",
    redirect: "/user/login",
    component: UserLayout,
    children: [
      {
        path: "/user/login",
        name: "Login",
        meta: {
          title: "登录页",
        },
        component: () => import("@/views/User/Login.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/config/division/module",
    component: BasicLayout,
    children: [
      {
        path: "/config",
        redirect: "/config/division/module",
        meta: { icon: "", title: "配置" },
        component: BlankLayout,
        children: [
          {
            path: "/config/division",
            redirect: "/config/division/module",
            meta: { icon: "appstore", title: "项目划分" },
            component: BlankLayout,
            children: [
              {
                path: "/config/division/module",
                name: "Module",
                meta: { icon: "", title: "模块" },
                component: () => import("@/views/Config/Division/Module.vue"),
              },
              {
                path: "/config/division/service",
                name: "Service",
                meta: { icon: "", title: "服务" },
                component: () => import("../views/Config/Division/Service.vue"),
              },
              {
                path: "/config/division/page",
                name: "Page",
                meta: { icon: "", title: "页面" },
                component: () => import("../views/Config/Division/Page.vue"),
              },
              {
                path: "/config/division/client",
                name: "Client",
                meta: { icon: "", title: "客户端" },
                component: () => import("../views/Config/Division/Client.vue"),
              },
              {
                path: "/config/division/other",
                name: "Others",
                meta: { icon: "", title: "其他" },
                component: () => import("../views/Config/Division/Others.vue"),
              },
            ],
          },
          {
            path: "/config/interface",
            meta: { icon: "link", title: "接口信息" },
            component: BlankLayout,
            children: [
              {
                path: "/config/interface/list",
                name: "interfaceList",
                meta: { icon: "", title: "接口列表", keepAlive: true },
                component: () =>
                  import("@/views/Interface/Msg/InterfaceList.vue"),
              },
              {
                path: "/config/interface/script",
                name: "interfaceScript",
                hidden: true,
                meta: { icon: "", title: "SQL脚本" },
                component: () =>
                  import("@/views/Interface/Msg/InterfaceScript.vue"),
              },
              // {
              //   path: "/interface/msg/test",
              //   name: "interfaceTest",
              //   meta: { icon: "", title: "接口测试" },
              //   component: () =>
              //     import("@/views/Interface/Msg/InterfaceTest.vue"),
              // },
              // {
              //   path: "/interface/msg/doc",
              //   name: "interfaceDoc",
              //   meta: { icon: "", title: "接口文档" },
              //   component: () =>
              //     import("@/views/Interface/Msg/InterfaceDoc.vue"),
              // },
            ],
          },
          // {
          //   path: "/config/field",
          //   redirect: "/config/field/all",
          //   meta: { icon: "bank", title: "字段仓库" },
          //   component: BlankLayout,
          //   children: [
          //     {
          //       path: "/config/field/all",
          //       name: "AllField",
          //       meta: { icon: "", title: "全部字段" },
          //       component: () => import("../views/Config/Field/AllField.vue"),
          //     },
          //     {
          //       path: "/config/field/base",
          //       name: "BaseField",
          //       meta: { icon: "", title: "固定字段" },
          //       component: () => import("../views/Config/Field/BaseField.vue"),
          //     },
          //   ],
          // },
          // {
          //   path: "/config/role",
          //   redirect: "config/role/all",
          //   meta: { icon: "team", title: "权限信息" },
          //   component: BlankLayout,
          //   children: [
          //     {
          //       path: "/config/role/all",
          //       name: "AllRole",
          //       meta: { icon: "", title: "全部权限" },
          //       component: () => import("../views/Config/Role/AllRole.vue"),
          //     },
          //     {
          //       path: "/config/role/setting",
          //       name: "RoleSetting",
          //       meta: { icon: "", title: "权限设置" },
          //       component: () => import("../views/Config/Role/RoleSetting.vue"),
          //     },
          //   ],
          // },
          // {
          //   path: "/config/env",
          //   redirect: "/config/env/domain",
          //   meta: { icon: "apartment", title: "环境信息" },
          //   component: BlankLayout,
          //   children: [
          //     {
          //       path: "/config/env/domain",
          //       name: "Domain",
          //       meta: { icon: "", title: "域名" },
          //       component: () => import("../views/Config/Env/Domain.vue"),
          //     },
          //     {
          //       path: "/config/env/ecs",
          //       name: "ECS",
          //       meta: { icon: "", title: "ECS" },
          //       component: () => import("../views/Config/Env/Domain.vue"),
          //     },
          //     {
          //       path: "/config/env/storage",
          //       name: "Storage",
          //       meta: { icon: "", title: "存储" },
          //       component: () => import("../views/Config/Env/Domain.vue"),
          //     },
          //     {
          //       path: "/config/env/msg",
          //       name: "Msg",
          //       meta: { icon: "", title: "消息" },
          //       component: () => import("../views/Config/Env/Domain.vue"),
          //     },
          //   ],
          // },
        ],
      },
      {
        path: "/system",
        redirect: "/system/company/list",
        meta: { icon: "", title: "平台" },
        component: BlankLayout,
        children: [
          {
            path: "/system/company",
            redirect: "/system/company/list",
            meta: { icon: "appstore", title: "公司管理" },
            component: BlankLayout,
            children: [
              {
                path: "/system/company/list",
                name: "CompanyList",
                meta: { icon: "", title: "公司列表" },
                component: () => import("@/views/System/Company/List.vue"),
              },
              {
                path: "/system/company/license",
                name: "CompanyLicense",
                meta: { icon: "", title: "直播间管理" },
                component: () => import("@/views/System/Company/License.vue"),
              },
            ],
          },
          {
            path: "/system/data",
            redirect: "/system/data/dict",
            meta: { icon: "appstore", title: "系统数据" },
            component: BlankLayout,
            children: [
              {
                path: "/system/data/permission",
                name: "Permission",
                meta: { icon: "", title: "权限管理" },
                component: () => import("@/views/System/Data/Permission.vue"),
              },
              {
                path: "/system/data/role",
                name: "Role",
                meta: { icon: "", title: "角色管理" },
                component: () => import("@/views/System/Data/Role.vue"),
              },
              {
                path: "/system/data/role/permission",
                name: "RolePermission",
                meta: { icon: "", title: "角色权限" },
                hidden: true,
                component: () =>
                  import("@/views/System/Data/RolePermission.vue"),
              },
              {
                path: "/system/data/dict",
                name: "Dict",
                meta: { icon: "", title: "字典管理" },
                component: () => import("@/views/System/Data/Dict.vue"),
              },
              {
                path: "/system/data/junkwords",
                name: "JunkWords",
                meta: { icon: "", title: "垃圾词管理" },
                component: () => import("@/views/System/Data/JunkWords.vue"),
              },
            ],
          },
          {
            path: "/system/manger",
            redirect: "/system/manger/app",
            meta: { icon: "database", title: "系统管理" },
            component: BlankLayout,
            children: [
              {
                path: "/system/manger/app",
                name: "AppVersion",
                meta: { icon: "", title: "APP版本" },
                component: () => import("@/views/System/Manger/AppVersion.vue"),
              },
            ],
          },
        ],
      },
      // {
      //   path: "/mysql",
      //   redirect: "/mysql/database/all",
      //   meta: { icon: "", title: "数据库" },
      //   component: BlankLayout,
      //   children: [
      //     {
      //       path: "/mysql/database",
      //       redirect: "/mysql/database/all",
      //       meta: { icon: "database", title: "数据结构" },
      //       component: BlankLayout,
      //       children: [
      //         {
      //           path: "/mysql/database/all",
      //           name: "MysqlAll",
      //           meta: { icon: "", title: "全部表" },
      //           component: () => import("@/views/Mysql/Database/AllTable.vue"),
      //         },
      //         {
      //           path: "/mysql/database/one",
      //           name: "MysqlOne",
      //           meta: { icon: "", title: "表字段" },
      //           component: () =>
      //             import("@/views/Mysql/Database/TableField.vue"),
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   path: "/redis",
      //   redirect: "/redis/database/all",
      //   meta: { icon: "", title: "缓存" },
      //   component: BlankLayout,
      //   children: [
      //     {
      //       path: "/redis/database",
      //       redirect: "/redis/database/all",
      //       meta: { icon: "database", title: "数据结构" },
      //       component: BlankLayout,
      //       children: [
      //         {
      //           path: "/redis/database/all",
      //           name: "RedisAll",
      //           meta: { icon: "", title: "全部表" },
      //           component: () => import("@/views/Redis/Database/AllTable.vue"),
      //         },
      //         {
      //           path: "/redis/database/one",
      //           name: "RedisOne",
      //           meta: { icon: "", title: "表字段" },
      //           component: () =>
      //             import("@/views/Redis/Database/TableField.vue"),
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   path: "/interface",
      //   redirect: "/interface/common/header",
      //   meta: { icon: "", title: "接口" },
      //   component: BlankLayout,
      //   children: [
      //     {
      //       path: "/interface/common",
      //       redirect: "/interface/common/header",
      //       meta: { icon: "menu", title: "通用字段" },
      //       component: BlankLayout,
      //       children: [
      //         {
      //           path: "/interface/common/header",
      //           name: "CommonHeader",
      //           meta: { icon: "", title: "请求头" },
      //           component: () => import("@/views/Interface/Common/index.vue"),
      //         },
      //         {
      //           path: "/interface/common/request",
      //           name: "CommonRequest",
      //           meta: { icon: "", title: "请求参数" },
      //           component: () => import("@/views/Interface/Common/index.vue"),
      //         },
      //         {
      //           path: "/interface/common/response",
      //           name: "CommonResponse",
      //           meta: { icon: "", title: "响应参数" },
      //           component: () => import("@/views/Interface/Common/index.vue"),
      //         },
      //       ],
      //     },
      // {
      //   path: "/interface/msg",
      //   meta: { icon: "link", title: "接口信息" },
      //   component: BlankLayout,
      //   children: [
      //     {
      //       path: "/interface/msg/list",
      //       name: "interfaceList",
      //       meta: { icon: "", title: "接口列表", keepAlive: true },
      //       component: () =>
      //         import("@/views/Interface/Msg/InterfaceList.vue"),
      //     },
      //     {
      //       path: "/interface/msg/script",
      //       name: "interfaceScript",
      //       hidden: true,
      //       meta: { icon: "", title: "SQL脚本" },
      //       component: () =>
      //         import("@/views/Interface/Msg/InterfaceScript.vue"),
      //     },
      //     {
      //       path: "/interface/msg/test",
      //       name: "interfaceTest",
      //       meta: { icon: "", title: "接口测试" },
      //       component: () =>
      //         import("@/views/Interface/Msg/InterfaceTest.vue"),
      //     },
      //     {
      //       path: "/interface/msg/doc",
      //       name: "interfaceDoc",
      //       meta: { icon: "", title: "接口文档" },
      //       component: () =>
      //         import("@/views/Interface/Msg/InterfaceDoc.vue"),
      //     },
      //   ],
      // },
      //   ],
      // },
      // {
      //   path: "/storage",
      //   // redirect: "/interface/common/header",
      //   meta: { icon: "", title: "文件存储" },
      //   component: BlankLayout,
      //   children: [],
      // },
      // {
      //   path: "/monitor",
      //   // redirect: "/interface/common/header",
      //   meta: { icon: "", title: "监控" },
      //   component: BlankLayout,
      //   children: [],
      // },
      // {
      //   path: "",
      //   redirect: "",
      //   meta: { icon: "", title: "" },
      //   component: BlankLayout,
      //   children: [
      //     {
      //       path: "",
      //       name: "",
      //       meta: { icon: "", title: "" },
      //       component: () => import(""),
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   path: "/exception/404",
  //   name: "NOTFOUND",
  //   meta: {},
  //   component: () => import(""),
  // },
];

export default routerMap;
