<template>
  <div class="right-content">
    <NoticeIcon class="notice-icon" />
    <AvatarDropdown class="avatar-dropdown" />
  </div>
</template>

<script>
import NoticeIcon from "./NoticeIcon.vue";
import AvatarDropdown from "./AvatarDropdown.vue";

export default {
  components: {
    NoticeIcon,
    AvatarDropdown,
  },
};
</script>

<style lang="less">
.right-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  .notice-icon {
    display: inline-block;
    padding-left: 10px;
    margin-right: 10px;
  }
  .avatar-dropdown {
    display: inline-block;
    padding-left: 20px;
  }
}
</style>
